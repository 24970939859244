<template>
    <div id="footer" class="mt-auto">
        <!-- Modal GDPR -->
        <div id="modalGDPRFooter" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="modalGDPRFooterLabel" aria-hidden="true" style="display: none;">
            <div class="modal-dialog modal-dialog-centered modal-lg modal-dialog-scrollable">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title mt-0" id="modalGDPRFooterLabel">{{$t('components.footer.modale.gdpr.titolo')}}</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div id="modal-body" class="modal-body">
                        <p v-html="$t('components.footer.modale.gdpr.testo')"></p>
                    </div>
                </div><!-- /.modal-content -->
            </div><!-- /.modal-dialog -->
        </div>
        <!-- /Modal GDPR -->

        <!-- Terms and conditions modal -->
        <div class="modal fade" id="termsAndConditionsModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-lg modal-dialog-scrollable" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLongTitle">{{$t('components.footer.modale.terminiECondizioni.titolo')}}</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <span v-html="$t('components.footer.modale.terminiECondizioni.testo')"></span>
                    </div>
                </div>
            </div>
        </div>
        <!-- /Terms and conditions modal -->

        <!-- Cookie modal -->
        <div class="modal fade" id="cookieModal" tabindex="-1" role="dialog" aria-labelledby="cookieModal" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-lg modal-dialog-scrollable" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="cookieModalTitle">{{$t('components.footer.modale.cookie.titolo')}}</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <span v-html="$t('components.footer.modale.cookie.testo')"></span>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-primary waves-effect waves-light" data-dismiss="modal" @click="showCookiebot()">{{$t('components.footer.rivediConsensi')}}</button>
                    </div>
                </div>
            </div>
        </div>
        <!-- /Cookie modal -->

        <!-- Cookie Bot Config modal -->
        <div class="modal fade" id="cookieBotConfigModal" data-backdrop="static" tabindex="-1" role="dialog" aria-labelledby="cookieModal" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-lg modal-dialog-scrollable" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="cookieBotConfigModalTitle">{{$t('components.footer.modale.cookie.titolo')}}</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div ref="cookieBotContainer"></div>
                    </div>
                </div>
            </div>
        </div>
        <!-- /Cookie Bot Config modal -->

        <!-- Desktop footer -->
        <footer class="d-xl-block d-none footer position-relative" :style="footerFix ? '' : 'left:0px'">
            <div class="container-fluid">
                <div class="row align-items-center">
                    <div :class="footerFix ? 'col-sm-5' : 'col-sm-6'">
                        <div>
                            <span>{{$t('components.footer.tradeMark')}}</span>
                            <span class="pl-4 pr-3">Disponibile sugli store</span>
                            <a href="https://play.google.com/store/apps/details?id=com.app.concorsando&gl=IT" target="_blank" rel="noopener">
                                <img src="../assets/images/new-image/play_store.png" height="20" class="pr-2 vertical-align-text-bottom">
                            </a>
                            <a href="https://apps.apple.com/it/app/concorsando-it/id543489205" target="_blank" rel="noopener">
                                <img src="../assets/images/new-image/apple_store.png" height="20" class="px-2 vertical-align-text-bottom">
                            </a>
                            <a href="https://appgallery.huawei.com/#/app/C101496801?sharePrepath=ag&locale=it_IT&source=appshare&subsource=C10149680" target="_blank" rel="noopener">
                                <img src="../assets/images/new-image/huawei_store.png" height="20" class="px-2 vertical-align-text-bottom">
                            </a>
                        </div>
                    </div>
                    <div :class="footerFix ? 'col-sm-7' : 'col-sm-6'">
                        <div class="text-sm-right d-none d-sm-block">
                            <ul class="nav justify-content-end">
                                <li class="nav-item">
                                    <a style="color: #3051d3;" class="py-0 nav-link cursorPointer" data-toggle="modal" data-target="#termsAndConditionsModal">{{$t('components.footer.terminiECondizioni')}}</a>
                                </li>
                                <li class="nav-item">
                                    <!--a style="color: #3051d3;" class="py-0 nav-link cursorPointer" data-toggle="modal" data-target="#modalGDPRFooter">{{$t('components.footer.informativaPrivacy')}}</a-->
                                    <a href="https://www.concorsando.it/blog/informativa-privacy/#h-titolare-del-trattamento-dei-dati-e-dpo" target="_blank" >{{$t('components.footer.informativaPrivacy')}}</a>
                                </li>
                                <li class="nav-item">
                                    <a style="color: #3051d3;" class="py-0 nav-link cursorPointer" data-toggle="modal" data-target="#cookieModal">{{$t('components.footer.informativaCookie')}}</a>
                                </li>
                                <li class="nav-item">
                                    <a style="color: #3051d3;" class="py-0 nav-link cursorPointer" @click="showCookiebot()">{{$t('components.footer.rivediConsensi')}}</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </footer>

        <!-- Mobile footer -->
        <div class="d-xl-none container-fluid py-3 bg-white text-center">
            <div class="row align-items-center">
                <div class="col d-flex flex-row flex-wrap justify-content-center">
                    <div class="flex-column">
                        <span>{{$t('components.footer.tradeMark')}}</span>
                    </div>
                    <div class="flex-column flex-nowrap">
                        <span class="pl-4 pr-3">Disponibile sugli store</span>
                        <a href="https://play.google.com/store/apps/details?id=com.app.concorsando&gl=IT" target="_blank" rel="noopener">
                            <img src="../assets/images/new-image/play_store.png" height="20" class="pr-2 vertical-align-text-bottom">
                        </a>
                        <a href="https://apps.apple.com/it/app/concorsando-it/id543489205" target="_blank" rel="noopener">
                            <img src="../assets/images/new-image/apple_store.png" height="20" class="px-2 vertical-align-text-bottom">
                        </a>
                        <a href="https://appgallery.huawei.com/#/app/C101496801?sharePrepath=ag&locale=it_IT&source=appshare&subsource=C10149680" target="_blank" rel="noopener">
                            <img src="../assets/images/new-image/huawei_store.png" height="20" class="px-2 vertical-align-text-bottom">
                        </a>
                    </div>
                </div>
            </div>
            <div class="row align-items-center mt-3">
                <div class="col">
                    <div class="d-block">
                        <ul class="nav justify-content-center">
                            <li class="nav-item">
                                <a style="color: #3051d3;" class="py-0 nav-link cursorPointer" data-toggle="modal" data-target="#termsAndConditionsModal">{{$t('components.footer.terminiECondizioni')}}</a>
                            </li>
                            <li class="nav-item">
                                <!--a style="color: #3051d3;" class="py-0 nav-link cursorPointer" data-toggle="modal" data-target="#modalGDPRFooter">{{$t('components.footer.informativaPrivacy')}}</a-->
                                <a href="https://www.concorsando.it/blog/informativa-privacy/#h-titolare-del-trattamento-dei-dati-e-dpo" target="_blank" >{{$t('components.footer.informativaPrivacy')}}</a>
                            </li>
                            <li class="nav-item">
                                <a style="color: #3051d3;" class="py-0 nav-link cursorPointer" data-toggle="modal" data-target="#cookieModal">{{$t('components.footer.informativaCookie')}}</a>
                            </li>
                            <li class="nav-item">
                                <a style="color: #3051d3;" class="py-0 nav-link cursorPointer" @click="showCookiebot()">{{$t('components.footer.rivediConsensi')}}</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {routesEnums} from '@/helpers/enums';

    export default {
        name: "Footer",
        computed: {
            footerFix() {
                return this.$route.name !== routesEnums.AUTH
                    && this.$route.name !== routesEnums.VERIFY_EMAIL
                    && this.$route.name !== routesEnums.RECOVERY_PASSWORD
                    && this.$route.name !== routesEnums.SEND_EMAIL_VERIFICATION
            }
        },
        mounted() {
        },
        methods: {
            /**
             * Codice per mostrare il messaggio di default di cookiebot nella modale. Non utilizzato perché apriamo
             * direttamente la modale per modificare i consensi.
             */
            setupCookieBot() {
                let scriptEl = document.createElement('script');
                scriptEl.setAttribute('id', 'CookieDeclaration');
                scriptEl.setAttribute('src', `https://consent.cookiebot.com/${process.env.VUE_APP_COOKIEBOT_ID}/cd.js`);
                scriptEl.setAttribute('type', 'text/javascript');
                this.$refs.cookieBotContainer.appendChild(scriptEl);
            },
            showCookiebot() {
                if (window.Cookiebot && window.Cookiebot.show) {
                    window.Cookiebot.show()
                }
            }
        }
    }
</script>

<style scoped lang="scss">
    html.theme-light, html.theme-dark {
        .vertical-align-text-bottom {
            vertical-align: text-bottom !important;
        }
    }
</style>
