<template>
    <div class="bg-primary bg-pattern pt-5 min-vh-100" style="display: flex; flex-direction: column;">
        <div class="home-btn d-none d-sm-block">
            <a href="https://www.concorsando.it/blog" target="_blank" rel="noopener"><i class="mdi mdi-home-variant h2 text-white"></i></a>
        </div>

        <div class="account-pages xm-xl-5 pt-xl-5 mb-3 pt-0">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="text-center mb-xl-5 mb-0">
                            <span class="logo"><img src="@/assets/images/new-image/concorsando-white.png" height="60" alt="logo"></span>
                            <h5 class="font-size-16 text-white-50 mb-xl-4 mb-3">{{$t('auth.sottotitoloLogo')}}</h5>
                        </div>
                    </div>
                </div>
                <!-- end row -->

                <div class="row justify-content-center">
                    <div class="col-xl-10 col-lg-8 col-md-10 col-12">
                        <div class="card" style="background:#f5f5f5">
                            <div class="row">
                                <div class="col-xl-6 d-xl-block d-none">
                                    <div class="card-body p-0">
                                        <img width="100%" src="@/assets/images/new-image/img-login-concorsando.png" alt="logo">
                                    </div>
                                </div>
                                <div class="col-xl-6">
                                    <router-view />
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <!-- end row -->
            </div>
        </div>
        <!-- end Account pages -->
        <Footer></Footer>
    </div>
</template>

<script>
    import Footer from '../components/Footer'
    import $ from "jquery";
    import Loader from "@/components/Loader";

    export default {
        name: "Auth",
        mounted() {
            $('#app-body').removeAttr('data-layout')
            $('#app-body').removeAttr('data-layout-size')
        },
        components: {
            Footer,
            Loader
        }
    }
</script>

<style scoped lang="css">
</style>
