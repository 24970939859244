<template>
    <!-- Modal overlay login -->
    <div id="modalOverlayLogin" class="modal show fade" data-backdrop="static" data-keyboard="false" tabindex="-1" role="dialog" aria-labelledby="modalOverlayLoginLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content bg-transparent border-0">
                <div id="modal-body-overlay-pause" class="modal-body">
                    <div class="w-100 text-center pb-4">
                        <img src="../assets/images/logo-concorsando-white.png">
                    </div>
                    <div class="w-100 text-center">
                        <div class="loader mx-auto"></div>
                    </div>
                </div>
            </div>
        </div>
        <!-- /.modal-dialog -->
    </div>
    <!-- /Modal overlay login -->
</template>

<script>
export default {
    name: "loader"
}
</script>